// extracted by mini-css-extract-plugin
export var animateCircle = "page-items-plate-filter-module--animate-circle--Ty+lP";
export var body = "page-items-plate-filter-module--body--03RvU";
export var bounce = "page-items-plate-filter-module--bounce--X+QNI";
export var checkedFilter = "page-items-plate-filter-module--checkedFilter--SQpha";
export var dropdown = "page-items-plate-filter-module--dropdown--Z1c7M";
export var dropdownItem = "page-items-plate-filter-module--dropdownItem--2afhl";
export var dropdownWrap = "page-items-plate-filter-module--dropdownWrap--OTryL";
export var dropdownWrapActive = "page-items-plate-filter-module--dropdownWrapActive--CM4yw";
export var filter = "page-items-plate-filter-module--filter--IRcwZ";
export var filterElem = "page-items-plate-filter-module--filterElem--osCTM";
export var filterElemTitle = "page-items-plate-filter-module--filterElemTitle--lYGDE";
export var filterElemTitleActive = "page-items-plate-filter-module--filterElemTitleActive--krUBj";
export var ldsRing = "page-items-plate-filter-module--lds-ring--TeN6r";
export var title = "page-items-plate-filter-module--title--vXkXN";