import React, { useContext, useEffect, useRef, useState } from 'react'
import className from 'classnames'
import PropTypes from 'prop-types'
import { FilterContext } from '../../../../utils/context'
import useOutsideClickEvent from '../../../../hooks/useOutsideClickEvent'
import * as style from './page-items-plate-filter.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'

const filterTitles = ['All Expertise', 'All Industries', 'All Platforms']

const FilterItem = ({
  type,
  index,
  dropdownCategoriesData,
  activeFilterDropdown,
  changeFilterItemDropdownState,
}) => {
  const [filterTitle, setFilterTitle] = useState(filterTitles[index])
  const [isItemSelected, setIsItemSelected] = useState(false)
  const { filterQuery, setFilterQuery } = useContext(FilterContext)
  const dropdownRef = useRef(null)
  const click = useOutsideClickEvent(dropdownRef)

  const selectFilterCategory = (data, name) => {
    setFilterTitle(name)
    setFilterQuery({
      type,
      data,
      name,
    })
    changeFilterItemDropdownState(null)
  }
  const resetFilter = () => {
    setFilterQuery({
      type: null,
      data: null,
      name: null,
    })
    changeFilterItemDropdownState(null)
  }

  useEffect(() => {
    if (!click) changeFilterItemDropdownState(null)
  }, [click])

  useEffect(() => {
    if (type !== filterQuery.type) {
      setFilterTitle(filterTitles[index])
    } else {
      setFilterTitle(filterQuery.name)
    }
  }, [filterQuery])

  useEffect(() => {
    setIsItemSelected(!filterTitles.includes(filterTitle))
  }, [filterTitle])

  return (
    <div ref={dropdownRef} className={style.filterElem}>
      <button
        type="button"
        onClick={() => changeFilterItemDropdownState(index)}
        className={className(
          isItemSelected && style.checkedFilter,
          style.filterElemTitle,
          activeFilterDropdown === index && style.filterElemTitleActive
        )}
      >
        {filterTitle}
      </button>
      <div
        className={className(
          style.dropdown,
          activeFilterDropdown === index && style.dropdownWrapActive
        )}
      >
        <ul className={style.dropdownWrap}>
          <li className={className(style.dropdownItem)}>
            <div onClick={resetFilter}>{filterTitles[index]}</div>
          </li>
          {dropdownCategoriesData.map((item, i) => (
            <li key={keyGenerator(i, item.name)} className={style.dropdownItem}>
              <div
                onKeyDown={() => selectFilterCategory(item.slug, item.name)}
                onClick={() => selectFilterCategory(item.slug, item.name)}
              >
                {item.name}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
FilterItem.propTypes = {
  changeFilterItemDropdownState: PropTypes.func.isRequired,
  activeFilterDropdown: PropTypes.number,
  dropdownCategoriesData: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
}
FilterItem.defaultProps = {
  activeFilterDropdown: null,
}

export default FilterItem
