import React, { useRef } from 'react'
import className from 'classnames'
import PropTypes, { shape } from 'prop-types'
import * as style from './portfolio-item-plate.module.scss'
import LinkCopy from '../../../common/LinkCopy/LinkCopy'
import Modal from '../../../common/Modal/Modal'
import ModalVideo from '../../../common/Modal/ModalVideo/ModalVideo'
import PlateTextContent from './PlateTextContent'

const PageItemPlate = ({
  copyLink,
  isPage,
  plateData: { portfolioItem, portfolioTechnologies, uri },
}) => {
  const poster = portfolioItem?.image?.localFile?.url
  const { plateBorderColor, plateBorderWidth, imageShadow, plateVideo } =
    portfolioItem

  const borderStyle = {
    borderColor: plateBorderColor,
    borderWidth: `${plateBorderWidth || 0}px`,
    borderStyle: 'solid',
  }

  const modalRef = useRef()
  const openVideo = () => {
    modalRef.current.openModal()
  }

  return (
    <div
      style={{
        ...borderStyle,
      }}
      className={className(style.body)}
    >
      <div
        style={{
          backgroundImage: `url('${
            !plateVideo &&
            portfolioItem.image &&
            portfolioItem.image.localFile.url
          }')`,
        }}
        className={className(
          style.background,
          !plateVideo && imageShadow && style.plateShadow
        )}
      />
      <div className={className(style.container, 'container')}>
        {copyLink && (
          <div className={style.copyLinkMobile}>
            <LinkCopy link={copyLink} />
          </div>
        )}
        <div>
          <PlateTextContent
            portfolioItem={portfolioItem}
            portfolioTechnologies={portfolioTechnologies}
            isPage={isPage}
            uri={uri}
            copyLink={copyLink}
            openVideo={openVideo}
          />
        </div>
        <div className={style.videoWrap}>
          {!plateVideo && portfolioItem.image && (
            <div
              style={{
                backgroundImage: `url('${
                  !plateVideo &&
                  portfolioItem.image &&
                  portfolioItem.image.localFile.url
                }')`,
              }}
              className={style.videoImage}
            />
          )}
          {plateVideo && plateVideo?.localFile?.url && (
            <video
              playsInline
              autoPlay="autoplay"
              muted="muted"
              loop="loop"
              className={style.video}
              poster={poster}
            >
              <source src={plateVideo.localFile.url} type="video/mp4" />
            </video>
          )}
        </div>
      </div>
      <Modal ref={modalRef}>
        <ModalVideo videoData={portfolioItem.embeddedHtml} />
      </Modal>
    </div>
  )
}

PageItemPlate.propTypes = {
  plateData: PropTypes.shape({
    portfolioItem: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      embeddedHtml: PropTypes.string,
      achievements: PropTypes.string,
    }),
    industry: PropTypes.shape({
      industry: shape({}),
      title: PropTypes.string,
      description: PropTypes.string,
      embeddedHtml: PropTypes.string,
      achievements: PropTypes.string,
    }),
    portfolioTechnologies: PropTypes.shape({
      node: PropTypes.arrayOf(PropTypes.string),
    }),
    uri: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
  copyLink: PropTypes.string,
  isPage: PropTypes.bool,
}
PageItemPlate.defaultProps = {
  copyLink: '',
  isPage: false,
}
export default PageItemPlate
