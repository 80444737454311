import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as style from './page-items-plate-filter.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'
import FilterItem from './FilterItem'
import {
  FILTER_TYPE_INDUSTRY,
  FILTER_TYPE_PLATFORMS,
  FILTER_TYPE_SERVICES,
} from '../../../../types/types'

const types = [
  FILTER_TYPE_SERVICES,
  FILTER_TYPE_INDUSTRY,
  FILTER_TYPE_PLATFORMS,
]
const PortfolioFilter = ({
  data: {
    allWpPortfolioItem,
    allWpPortfolioService,
    allWpPortfolioIndustry,
    allWpPortfolioPlatform,
  },
}) => {
  const [dropdownActiveItem, setDropdownActiveItem] = useState(null)

  const validFiltersService = []
  const validFiltersIndustry = []
  const validFiltersPlatform = []

  allWpPortfolioItem.edges.forEach((elem) => {
    const {
      node: { portfolioServices, portfolioIndustries, portfolioPlatforms },
    } = elem
    portfolioServices.nodes.forEach((e) => {
      if (!validFiltersService.includes(e.slug)) {
        validFiltersService.push(e.slug)
      }
    })
    portfolioIndustries.nodes.forEach((e) => {
      if (!validFiltersIndustry.includes(e.slug)) {
        validFiltersIndustry.push(e.slug)
      }
    })
    portfolioPlatforms.nodes.forEach((e) => {
      if (!validFiltersPlatform.includes(e.slug)) {
        validFiltersPlatform.push(e.slug)
      }
    })
  })

  const filterData = [
    allWpPortfolioService.nodes.filter((e) =>
      validFiltersService.includes(e.slug)
    ),
    allWpPortfolioIndustry.nodes.filter((e) =>
      validFiltersIndustry.includes(e.slug)
    ),

    allWpPortfolioPlatform.nodes.filter((e) =>
      validFiltersPlatform.includes(e.slug)
    ),
  ]

  const changeDropdownState = (value) => {
    setDropdownActiveItem((prev) => (prev === value ? null : value))
  }

  return (
    <div className={style.body}>
      <p className={style.title}>Sort Cases By:</p>
      <div className={style.filter}>
        {filterData.map((elem, index) => (
          <div key={keyGenerator(index, elem[index].name)}>
            <FilterItem
              type={types[index]}
              index={index}
              elem={elem}
              changeFilterItemDropdownState={changeDropdownState}
              activeFilterDropdown={dropdownActiveItem}
              dropdownCategoriesData={elem}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

PortfolioFilter.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default PortfolioFilter
