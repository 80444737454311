// extracted by mini-css-extract-plugin
export var activeMobileContent = "portfolio-item-plate-module--activeMobileContent--AfSxU";
export var animateCircle = "portfolio-item-plate-module--animate-circle--JR5m4";
export var background = "portfolio-item-plate-module--background--KtRb8";
export var body = "portfolio-item-plate-module--body--O1Wo7";
export var bounce = "portfolio-item-plate-module--bounce--Hpkl3";
export var container = "portfolio-item-plate-module--container--Nw-jg";
export var copyLinkMobile = "portfolio-item-plate-module--copyLinkMobile--AR3WI";
export var image = "portfolio-item-plate-module--image--aFNqf";
export var ldsRing = "portfolio-item-plate-module--lds-ring--hrWZv";
export var mobileContent = "portfolio-item-plate-module--mobileContent--UMhHM";
export var mobileContentWrap = "portfolio-item-plate-module--mobileContentWrap--ahWU5";
export var plateShadow = "portfolio-item-plate-module--plateShadow--L1ert";
export var video = "portfolio-item-plate-module--video--xJqkG";
export var videoImage = "portfolio-item-plate-module--videoImage--lCLkV";