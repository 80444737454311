// extracted by mini-css-extract-plugin
export var achievements = "desctop-content-module--achievements--R-58t";
export var animateCircle = "desctop-content-module--animate-circle--ZefXg";
export var bounce = "desctop-content-module--bounce--sSjYw";
export var button = "desctop-content-module--button--72oKM";
export var content = "desctop-content-module--content--55LEg";
export var copyLink = "desctop-content-module--copyLink--nmpr1";
export var description = "desctop-content-module--description--Cm1Mq";
export var descriptionButton = "desctop-content-module--descriptionButton--SIoCN";
export var descriptionButtonActive = "desctop-content-module--descriptionButtonActive--OlCST";
export var descriptionOpen = "desctop-content-module--descriptionOpen--B2r5T";
export var descriptionText = "desctop-content-module--descriptionText--cgXs+";
export var elem = "desctop-content-module--elem--C-Zj1";
export var ldsRing = "desctop-content-module--lds-ring--dVaWe";
export var mobileDescription = "desctop-content-module--mobileDescription--R2D9-";
export var title = "desctop-content-module--title--xvXPP";
export var titleWrapper = "desctop-content-module--titleWrapper--90+4z";