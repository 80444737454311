import React from 'react'
import PropTypes from 'prop-types'
import * as style from './link-copy.module.scss'
import useAlert from '../../../hooks/useAlert'
import { ALERT_TYPE_LINK_COPY } from '../../../types/types'

const LinkCopy = ({ link }) => {
  const { addError } = useAlert()
  const copyAddress = () => {
    navigator.clipboard.writeText(link)
    addError('The link is copied', ALERT_TYPE_LINK_COPY)
  }

  return (
    <button
      type="button"
      aria-label="copy link"
      className={style.link}
      onClick={copyAddress}
    />
  )
}

LinkCopy.propTypes = {
  link: PropTypes.string.isRequired,
}

export default LinkCopy
