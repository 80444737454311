import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import cn from 'classnames'
import MainLayout from '../layouts/MainLayout'
import PageMainSection from '../components/reusable/PageMainSection/PageMainSection'
import Section from '../components/common/SectionComponent/Section'
import PortfolioFilter from '../components/Pages/Portfolio/PortfolioFilter/PortfolioFilter'
import { FilterContext } from '../utils/context'
import {
  FILTER_TYPE_INDUSTRY,
  FILTER_TYPE_PLATFORMS,
  FILTER_TYPE_SERVICES,
} from '../types/types'
import HiddenScrollItem from '../components/common/HiddenScrollItem/HiddenScrollItem'
import keyGenerator from '../utils/keyGenerator'
import PortfolioItemPlate from '../components/Pages/Portfolio/PortfolioItemPlate/PortfolioItemPlate'
import * as style from '../../assets/scss/pages/portfolio.module.scss'
// helpers
const checkElementsType = (type) => {
  switch (type) {
    case FILTER_TYPE_SERVICES:
      return 'portfolioServices'
    case FILTER_TYPE_INDUSTRY:
      return 'portfolioIndustries'
    case FILTER_TYPE_PLATFORMS:
      return 'portfolioPlatforms'
    default:
      return 'portfolioServices'
  }
}

const getQueryParams = (query) => ({
  type: query.split('?')[1].split('=')[0],
  queryData: query.split('?')[1].split('=')[1],
})

const Portfolio = ({ data, location }) => {
  const seoData = data.wpPage.metaTags
  const portfolioPlates = data.allWpPortfolioItem.edges
  const [plates, setPlates] = useState(portfolioPlates)
  const [filterQuery, setFilterQuery] = useState({
    type: '',
    data: '',
    name: '',
  })

  const platesFilter = (type) => {
    const filteredArr = portfolioPlates.filter((elem) => {
      const servicesNodesArray = elem.node[checkElementsType(type)].nodes.map(
        (item) => item.slug
      )
      return servicesNodesArray.includes(filterQuery.data)
    })
    setPlates(filteredArr)
  }

  const returnFoundElemName = (type, queryData) => {
    let name = ''

    portfolioPlates.forEach((item) => {
      item.node[checkElementsType(type)].nodes.forEach((elem) => {
        if (elem.slug === queryData) name = elem.name
      })
    })
    return name || 'All platforms'
  }

  useEffect(() => {
    if (filterQuery && filterQuery.type) {
      platesFilter(filterQuery.type)
    } else {
      setPlates(portfolioPlates)
    }
  }, [filterQuery])

  useEffect(() => {
    if (location.search) {
      const { type, queryData } = getQueryParams(location.search)
      const name = returnFoundElemName(type, queryData)
      setFilterQuery({
        type,
        data: queryData,
        name,
      })

      setTimeout(() => {
        scrollTo(`#${queryData}`)
      }, 1000)
    }
  }, [location])

  return (
    <MainLayout
      wrapClass="overflow-visible"
      seoData={seoData}
      location={location}
    >
      <FilterContext.Provider value={{ filterQuery, setFilterQuery }}>
        <PageMainSection
          hiddenTitle={seoData.seoHiddenTitle}
          title={data.wpPage.page_portfolio.title}
          description={data.wpPage.page_portfolio.description}
          image="portfolio_bg.png"
          imageMobile="portfolio_bg_mobile.png"
        />
        <Section hideText isLight>
          <PortfolioFilter data={data} />
        </Section>

        <section className={cn('container-fluid', style.plateWrapper)}>
          {plates.map((item, index) => {
            const { slug } = item.node.portfolioIndustries.nodes[0]
            return (
              item && (
                <div
                  className="wrapper"
                  key={keyGenerator(index, item.node.portfolioItem.title)}
                >
                  <HiddenScrollItem id={slug} />
                  <HiddenScrollItem id={item.node.slug} />
                  <PortfolioItemPlate
                    plateData={item.node}
                    copyLink={`${location.origin}/portfolio/#${item.node.slug}`}
                    isPage={item.node.portfolioItem.type === 'case_study'}
                  />
                </div>
              )
            )
          })}
        </section>
      </FilterContext.Provider>
    </MainLayout>
  )
}
export const query = graphql`
  {
    wpPage(databaseId: { eq: 22 }) {
      metaTags {
        seoTitle
        seoHiddenTitle
        seoDescription
        seoKeywords
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }
      page_portfolio {
        title
        description
      }
    }
    allWpPortfolioItem(sort: { fields: menuOrder }) {
      edges {
        node {
          portfolioIndustries {
            nodes {
              name
              slug
            }
          }
          portfolioServices {
            nodes {
              name
              slug
            }
          }
          portfolioPlatforms {
            nodes {
              name
              slug
            }
          }
          portfolioTechnologies {
            nodes {
              name
              slug
            }
          }
          portfolioItem {
            type
            buttonText
            embeddedHtml
            achievements
            image {
              localFile {
                url
              }
            }
            description
            title
            plateBorderColor
            plateBorderWidth
            imageShadow
            plateVideo {
              localFile {
                url
              }
            }
            caseStudySections {
              ... on WpPortfolio_item_Portfolioitem_CaseStudySections_Quote {
                name
              }
            }
          }
          slug
          uri
          link
        }
      }
    }
    allWpPortfolioService {
      nodes {
        name
        slug
      }
    }
    allWpPortfolioIndustry {
      nodes {
        name
        slug
      }
    }
    allWpPortfolioPlatform {
      nodes {
        name
        slug
      }
    }
  }
`
Portfolio.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.shape({
      metaTags: PropTypes.shape({}),
      page_portfolio: PropTypes.shape({
        hiddenTitle: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
    allWpPortfolioService: PropTypes.shape({}),
    allWpPortfolioIndustry: PropTypes.shape({}),
    allWpPortfolioPlatform: PropTypes.shape({}),
    allWpPortfolioItem: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.any),
    }),
  }).isRequired,
  location: PropTypes.shape({
    origin: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
}
export default Portfolio
