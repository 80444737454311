import Fade from 'react-reveal/Fade'
import React, { useState } from 'react'
import cn from 'classnames'
import * as style from './desctop-content.module.scss'
import LinkCopy from '../../../common/LinkCopy/LinkCopy'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'

const DescriptionContent = ({
  portfolioItem,
  portfolioTechnologies,
  copyLink,
  isPage,
  uri,
  openVideo,
}) => {
  const { description, achievements } = portfolioItem
  const [descriptionState, setDescriptionState] = useState(false)

  const Description = () => (
    <div
      className={cn(
        style.description,
        descriptionState && style.descriptionOpen
      )}
    >
      <Fade left>
        <p className={style.descriptionText}>{description}</p>
      </Fade>
      <Fade bottom>
        <div className={style.achievements}>
          <p className={style.elem}>
            <span className="text-color--main">Achievements: </span>
            {achievements}
          </p>
          <p className={style.elem}>
            <span className="text-color--main">Technologies: </span>
            {portfolioTechnologies &&
              portfolioTechnologies.nodes.map((elem, i) => (
                <span key={elem.name}>
                  {i ? ' | ' : ''}
                  {elem.name}
                </span>
              ))}
          </p>
        </div>
      </Fade>
    </div>
  )

  return (
    <>
      <div className={style.content}>
        <Fade left>
          <div className={style.titleWrapper}>
            <h2 className={style.title}>{portfolioItem.title}</h2>
            {copyLink && (
              <div className={style.copyLink}>
                <LinkCopy link={copyLink} />
              </div>
            )}
          </div>
        </Fade>
        <Description />
        <Fade left>
          <>
            {isPage ? (
              <ButtonPrimary
                buttonData={{
                  url: { uri },
                  text: 'Discover more',
                }}
                btnClass={style.button}
              />
            ) : (
              <ButtonPrimary
                onClick={() => openVideo()}
                btnClass={style.button}
              >
                {portfolioItem.buttonText}
              </ButtonPrimary>
            )}
          </>
        </Fade>
        <div>
          <button
            onClick={() => setDescriptionState(!descriptionState)}
            onKeyDown={() => setDescriptionState(!descriptionState)}
            type="button"
            className={cn(
              style.descriptionButton,
              descriptionState && style.descriptionButtonActive
            )}
          >
            Read more
          </button>
          <div className={style.mobileDescription}>
            {descriptionState && <Description />}
          </div>
        </div>
      </div>
    </>
  )
}

export default DescriptionContent
